import React, { useState, useEffect } from 'react';
import { withRouter } from '../services';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Modal, Box, TextField, FormControlLabel, Switch, Checkbox } from '@mui/material';
import { Alert } from '@mui/material';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import ReactHtmlParser from 'react-html-parser'; 
import './styles/header.scss';
import logo from '../assets/images/landscapelogo.png';
import { config } from '../config';
import { delay, encrypt, decrypt } from '../services';
import { updateUser, updateToken, updateExpired, updateLogined, updateRememberLogin } from '../redux/actions';
import { Response, User, Token, RememberLogin } from '../types';
import { userApi } from '../api';

type Props = {
  location: Location,
	token: string,
  user: User,
	logined: boolean,
  expired: string,
  rememberLogin: RememberLogin,
  updateUser: Function,
  updateToken: Function,
  updateExpired: Function,
  updateLogined: Function,
  updateRememberLogin: Function
}

const Header: React.FC<Props> = (props) => {

	const [drawerOpen, setDrawerOpen] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isScrolled, setIsScrolled] = useState(false);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const [severity, setSeverity] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  const [formData, setFormData] = useState({
    username: props.rememberLogin.remember?props.rememberLogin.username:'',
    password: props.rememberLogin.remember&&props.rememberLogin.password!==''?decrypt(props.rememberLogin.password):'',
    rememberPassword: props.rememberLogin.remember,
  });
  const [registerData, setRegisterData] = useState({
    username: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    email: '',
    dateOfBirth: '',
    country: '',
    city: '',
    agreement: false,
  });
  const [email, setEmail] = useState('');
  const [formComponent, setFormComponent] = useState('Login');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Style for the modal box
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    bgcolor: '#ffffff',
    color: '#1d1d1d',
    border: '2px solid #f1f1f1',
    borderRadius: '0px',
    boxShadow: 24,
    p: 4,
  };

  const openModal = () => {
    handleOpen();
  }
  
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const validateForm = (formData: any) => {
    let errors = "";
    const regex = {
      username: /^[A-Za-z][A-Za-z0-9_]{4,30}$/,
      password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{6,30}$/,
      email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      date: /^\d{4}-\d{2}-\d{2}$/,
    };
  
    // Username validation
    if (!regex.username.test(formData.username)) {
      errors += 'Username must be at least 5 characters and not start with a number.<br/>';
    }
  
    // Password match validation
    if (!formData.password.trim() && formData.password !== formData.confirmPassword) {
      if (!regex.password.test(formData.password)) {
        errors += 'Password must be 6-30 characters long, with at least one lowercase, one uppercase, and one number.<br/>';
      } else {
        errors += 'Passwords do not match.<br/>';
      }
    }
  
    // First and Last name validation
    if (!formData.firstName.trim()) {
      errors += 'First Name cannot be empty.<br/>';
    }
    if (!formData.lastName.trim()) {
      errors += 'Last Name cannot be empty.<br/>';
    }
  
    // Email validation
    if (!regex.email.test(formData.email)) {
      errors += 'Email is not valid.<br/>';
    }
  
    // Date of Birth validation
    if (!regex.date.test(formData.dateOfBirth) || new Date(formData.dateOfBirth) > new Date()) {
      errors += 'Date of Birth is not valid.<br/>';
    }
  
    // Country and City validation
    if (!formData.country.trim()) {
      errors += 'Country cannot be empty.<br/>';
    }
    if (!formData.city.trim()) {
      errors += 'City cannot be empty.<br/>';
    }
    if (!formData.agreement) {
      errors += 'Please agree terms and conditions.<br/>';
    }
    if (errors !== "") {
      setMsg(errors);
      setSeverity('error');
      // Show the message
      setShowMessage(true);
      // Hide the message after 5 seconds
      delay(10000).then(()=>setShowMessage(false));
    }
    return errors === "";
  };

  const loginChange = (event: any) => {
    const { name, value, checked, type } = event.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };
  
  const logout = async () => {
    try{
      const responseLogout: Response = await userApi.logout(props.token);
      if(responseLogout.status === 200) {
        props.updateToken('');
        props.updateExpired('');
        props.updateUser(null);
        props.updateLogined(false);
      }
    } catch(e: Response|any) {
      props.updateToken('');
      props.updateExpired('');
      props.updateUser(null);
      props.updateLogined(false);
    }    
  }

  const registerChange = (event: any) => {
    const { name, value, checked, type } = event.target;
    setRegisterData({
      ...registerData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const registerSubmit = async (event: any) => {
    event.preventDefault();
    // Handle the registration logic here
    console.log(registerData);
    if(validateForm(registerData)) {
      setMsg('This feature is not available yet, please check it later');
      setSeverity('warning');
      setShowMessage(true);

      // Hide the message after 5 seconds
      await delay(5000);
      setShowMessage(false);
    }
  };

  const loginSubmit = async (event: any) => {
    event.preventDefault();
    // Handle form submission logic here
    try{
      const responseLogin: Response = await userApi.login(formData.username,formData.password);
      const token: Token = responseLogin.data;
      props.updateToken(token.token);
      props.updateExpired(token.expired);
      const responseUser: Response = await userApi.user(token.token);
      const user: User = responseUser.data;
      props.updateUser(user);
      props.updateLogined(true);
      if(formData.rememberPassword) {
        const remember = {remember: true, username: formData.username, password: encrypt(formData.password)};
        props.updateRememberLogin(remember);
      }else{
        const remember = {remember: false, username: '', password: ''};
        props.updateRememberLogin(remember);
      }
      handleClose();
    } catch(e: Response|any) {
      if(e.data) {
        setMsg(e.data?.msg);
        setSeverity('error');
        setShowMessage(true);
        props.updateToken('');
        props.updateExpired('');
        props.updateUser(null);
        props.updateLogined(false);
        // Hide the message after 5 seconds
        await delay(5000);
        setShowMessage(false);
      }
    }    
  };

  const forgetSubmit = async (event: any) => {
    event.preventDefault();
    // Handle the password reset logic here, typically involving sending an email
    console.log('Password reset email sent to:', email);
    setMsg('Reset password link was sent to your email! Please click the link within 30 min.');
    setSeverity('success');

    //setMsg('Email doesn't exist in the system! Please input correct email.');
    //setSeverity('error');

    // Show the message
    setShowMessage(true);
    // Hide the message after 5 seconds
    await delay(5000);
    setShowMessage(false);
  };

  const handleClickOnLink = (url: string, flag: boolean) => {
    if(flag) {
      window.open(url, '_target');
    } else {
      window.location.href = url;
    }
  };

  const menu = (
    <>
      <ListItem button component={Link} to="https://www.iceloof.com" onClick={(e: any)=>{e.preventDefault();handleClickOnLink("https://www.iceloof.com", false)}} key={'Home'} className="item">
        <ListItemText primary={'Home'} />
      </ListItem>
      <ListItem button component={Link} to="https://marketwatcher.iceloof.com" onClick={(e: any)=>{e.preventDefault();handleClickOnLink("https://marketwatcher.iceloof.com", false)}} key={'Market Watcher'} className="item">
        <ListItemText primary={'Market Watcher'} />
      </ListItem>
      <ListItem button component={Link} to="https://quant.iceloof.com" onClick={(e: any)=>{e.preventDefault();handleClickOnLink("https://quant.iceloof.com", false)}} key={'Quant Trader'} className="item">
        <ListItemText primary={'Quant Trader'} />
      </ListItem>
      <ListItem button component="a" href="https://blog.iceloof.com" onClick={(e: any)=>{e.preventDefault();handleClickOnLink("https://blog.iceloof.com", true)}} target="_blank" key={'Blog'} className="item">
        <ListItemText primary={'Blog'} />
      </ListItem>
      <ListItem button component={Link} to="https://www.iceloof.com/about" onClick={(e: any)=>{e.preventDefault();handleClickOnLink("https://www.iceloof.com/about", false)}} key={'About'} className="item">
        <ListItemText primary={'About'} />
      </ListItem>
      <ListItem button component={Link} to="https://www.iceloof.com/contact" onClick={(e: any)=>{e.preventDefault();handleClickOnLink("https://www.iceloof.com/contact", false)}} key={'Contact'} className="item">
        <ListItemText primary={'Contact'} />
      </ListItem>
    </>
  );

  const loginComponent = (
    <>
      <div className="title">Login To <span>Iceloof</span></div>
      <Box component="form" onSubmit={loginSubmit} noValidate>
        {showMessage && (severity === 'success'?(
          <div style={{margin: '0 auto', paddingBottom: '15px', width: '300px'}}>
            <Alert severity='success'>
              {msg}
            </Alert>
          </div>
        ):(
          <div style={{margin: '0 auto', paddingBottom: '15px', width: '300px'}}>
            <Alert severity='error'>
              {msg}
            </Alert>
          </div>
        ))}
        <TextField
          fullWidth
          label="Username"
          name="username"
          size="small"
          color="success"
          value={formData.username}
          onChange={loginChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Password"
          name="password"
          size="small"
          type="password"
          color="success"
          value={formData.password}
          onChange={loginChange}
          margin="normal"
        />
        <FormControlLabel
          control={
            <Switch
              color="success"
              checked={formData.rememberPassword}
              onChange={loginChange}
              name="rememberPassword"
            />
          }
          label="Remember Password"
        />
        <Button
          type="submit"
          fullWidth
          size="small"
          variant="contained"
          color="success"
          sx={{ marginTop: 2 }}
        >
          Login
        </Button>
        <Button
          sx={{ marginTop: 2 }}
          variant="text"
          size="small"
          color="success"
          onClick={()=>setFormComponent('Forget')}
        >
          Forgot Password?
        </Button>
        <Button
          sx={{ marginTop: 2, display: 'block' }}
          variant="text"
          size="small"
          color="success"
          onClick={()=>setFormComponent('Register')}
        >
          Register
        </Button>
      </Box>
    </>
  );

  const forgetComponent = (
    <>
      <div className="title">Reset Password</div>
      <Box component="form" onSubmit={forgetSubmit} noValidate>
        {showMessage && (severity === 'success'?(
          <div style={{margin: '0 auto', paddingBottom: '15px', width: '300px'}}>
            <Alert severity='success'>
              {msg}
            </Alert>
          </div>
        ):(
          <div style={{margin: '0 auto', paddingBottom: '15px', width: '300px'}}>
            <Alert severity='error'>
              {msg}
            </Alert>
          </div>
        ))}
        <TextField
          fullWidth
          label="Email"
          name="email"
          type="email"
          size="small"
          color="success"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          size="small"
          color="success"
          sx={{ marginTop: 2 }}
        >
          Submit
        </Button>
        <Button
          fullWidth
          variant="text"
          size="small"
          color="success"
          sx={{ marginTop: 2 }}
          onClick={()=>setFormComponent('Login')}
        >
          Back to Login
        </Button>
      </Box>
    </>
  );

  const registerComponent = (
    <>
      <div className="title">Register</div>
      <Box component="form" onSubmit={registerSubmit} noValidate>
        {showMessage && (severity === 'success'?(
          <div style={{margin: '0 auto', paddingBottom: '15px', width: '300px'}}>
            <Alert severity='success'>
              {ReactHtmlParser (msg)}
            </Alert>
          </div>
        ):(
          <div style={{margin: '0 auto', paddingBottom: '15px', width: '300px'}}>
            <Alert severity='error'>
              {ReactHtmlParser (msg)}
            </Alert>
          </div>
        ))}
        <TextField
          fullWidth
          label="Username"
          name="username"
          size="small"
          color="success"
          value={registerData.username}
          onChange={registerChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Password"
          name="password"
          type="password"
          size="small"
          color="success"
          value={registerData.password}
          onChange={registerChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Confirm Password"
          name="confirmPassword"
          type="password"
          size="small"
          color="success"
          value={registerData.confirmPassword}
          onChange={registerChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="First Name"
          name="firstName"
          size="small"
          color="success"
          value={registerData.firstName}
          onChange={registerChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Last Name"
          name="lastName"
          size="small"
          color="success"
          value={registerData.lastName}
          onChange={registerChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Email"
          name="email"
          type="email"
          size="small"
          color="success"
          value={registerData.email}
          onChange={registerChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Date of Birth"
          name="dateOfBirth"
          type="date"
          size="small"
          color="success"
          InputLabelProps={{ shrink: true }}
          value={registerData.dateOfBirth}
          onChange={registerChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Country"
          name="country"
          size="small"
          color="success"
          value={registerData.country}
          onChange={registerChange}
          margin="normal"
        />
        <TextField
          fullWidth
          label="City"
          name="city"
          size="small"
          color="success"
          value={registerData.city}
          onChange={registerChange}
          margin="normal"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={registerData.agreement}
              onChange={registerChange}
              name="agreement"
              size="small"
              color="success"
            />
          }
          label="I agree to the terms and conditions."
        />
        <Button
          type="submit"
          fullWidth
          size="small"
          color="success"
          variant="contained"
          sx={{ marginTop: 2 }}
        >
          Register
        </Button>
        <Button
          fullWidth
          variant="text"
          size="small"
          color="success"
          sx={{ marginTop: 2 }}
          onClick={()=>setFormComponent('Login')}
        >
          Back to Login
        </Button>
      </Box>
    </>
  );

	return (
		<div className={`header ${isScrolled || !(config.subdomain==='www'&&window.location.pathname==='/') ? 'scrolled' : ''}`}>
			<AppBar position="fixed" style={{ maxWidth: 1280, left: '50%', transform: 'translateX(-50%)', background: 'transparent', boxShadow: 'none' }}>
        <Toolbar>
          {isMobile && (
            <IconButton
              className="mobileMenuIcon"
              edge="start"
              color="inherit"
              aria-label="menu"
							style={{ marginRight: '15px' }}
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          )}
          <a href="/" onClick={(e: any)=>{e.preventDefault();handleClickOnLink("https://www.iceloof.com", false)}} style={{ flexGrow: 1, paddingTop: '15px' }}>
						<img src={logo} alt="logo" className="logo" />
          </a>
          {!isMobile && (
            <>
							<List component="nav" style={{ display: 'flex' }}>
                {menu}
							</List>
							{props.logined?<>Hi&nbsp;<i>{props.user.firstname}</i>&nbsp;<div className="pointer" onClick={()=>logout()}><LogoutIcon /></div></>:<Button size="small" variant="contained" color="success" onClick={()=>openModal()}>Sign In</Button>}
						</>
          )}
        </Toolbar>
      </AppBar>
      <Drawer className={`${config.subdomain === 'marketwatcher'?'dark-bg':''}`} anchor="left" open={drawerOpen} onClose={handleDrawerToggle} style={{ width: 240 }}>
        <div style={{ width: 240, textAlign: 'center' }}>
          <List>
            {menu}
          </List>
          {props.logined?<>Hi&nbsp;<i>{props.user.firstname}</i>&nbsp;<div style={{ marginTop: '12px' }} className="pointer" onClick={()=>logout()}><LogoutIcon /></div></>:<Button size="small" variant="contained" color="success" onClick={()=>openModal()}>Sign In</Button>}
        </div>
      </Drawer>
      <Modal
        open={open}
        onClose={handleClose}
        className="login-modal"
        aria-labelledby="login-modal-title"
        aria-describedby="login-modal-description"
      >
        <Box sx={style}>
          <CloseIcon className="pointer close-btn" onClick={handleClose}/>
          <div className="form">
            {formComponent === 'Login' ? loginComponent : formComponent === 'Forget' ? forgetComponent : registerComponent}
          </div>
        </Box>
      </Modal>
		</div>
	);
}

const mapStateToProps = (state: any) => ({
	user: state.user.user,
  token: state.user.token,
	logined: state.user.logined,
  expired: state.user.expired,
  rememberLogin: state.user.rememberLogin
});

const mapDispatchToProps = (dispatch: any) => {
	return {
    updateUser: bindActionCreators(updateUser, dispatch),
    updateToken: bindActionCreators(updateToken, dispatch),
    updateExpired: bindActionCreators(updateExpired, dispatch),
    updateLogined: bindActionCreators(updateLogined, dispatch),
    updateRememberLogin: bindActionCreators(updateRememberLogin, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));