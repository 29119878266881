import React,{ useEffect }  from 'react';
import { initGA } from './ga-init';
import { Routes, Route, Navigate } from 'react-router-dom';
import { withRouter, deepEqual } from './services';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateUser, updateToken, updateExpired, updateLogined } from './redux/actions/user';
import { userApi } from './api';
import { config } from './config';
import { Response, User } from './types';
import Header from './components/Header';
import Home from './pages/home';
import About from './pages/about';
import Contact from './pages/contact';
import MarketWatcher from './pages/marketwatcher';
import QuantTrader from './pages/quanttrader';

type Props = {
  location: Location,
	token: string,
  user: User,
	logined: boolean,
  expired: string,
  updateUser: Function,
  updateToken: Function,
  updateExpired: Function,
  updateLogined: Function,
}

const App: React.FC<Props> = (props) => {

  useEffect(() => {
    initGA();
  }, []);

  

  useEffect(() => {
    const getUser = async () => {
      if(props.logined){
        try {
          const responseUser: Response = await userApi.user(props.token);
          const user: User = responseUser.data;
          if(deepEqual(user,props.user)) {
            props.updateUser(user);
          }
        } catch (e) {
          props.updateToken('');
          props.updateExpired('');
          props.updateUser(null);
          props.updateLogined(false);
        }
      }
    }
    getUser();
    const interval = setInterval(getUser, 60000*15); // Fetch latest news every minute
    return () => {clearInterval(interval);}
  },[props]);

  return (
    <>
      {config.subdomain !== 'portfolio' && (<Header />)}
      <Routes>
        {/* Route for Home */}
        {config.subdomain === 'www' && (
        <Route path="/" element={<Home />} />)}

        {/* Route for About */}
        {config.subdomain === 'www' && (
        <Route path="/about" element={<About />} />)}

        {/* Route for Contact */}
        {config.subdomain === 'www' && (
        <Route path="/contact" element={<Contact />} />)}

        {/* Route for MarketWatcher */}
        {config.subdomain === 'marketwatcher' && (
        <Route path="/" element={<MarketWatcher />} />)}

        {/* Route for QuantTrader */}
        {config.subdomain === 'quant' && (
        <Route path="/" element={<QuantTrader />} />)}

        {/* Fallback route */}
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </>
  );
}

const mapStateToProps = (state: any) => ({
	user: state.user.user,
  token: state.user.token,
	logined: state.user.logined,
  expired: state.user.expired,
});

const mapDispatchToProps = (dispatch: any) => {
	return {
    updateUser: bindActionCreators(updateUser, dispatch),
    updateToken: bindActionCreators(updateToken, dispatch),
    updateExpired: bindActionCreators(updateExpired, dispatch),
    updateLogined: bindActionCreators(updateLogined, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
